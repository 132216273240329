<template>
  <div class="bg-gray-900 sticky top-0" style="font-family: Nunito;">
      <nav
        class="
        bg-gray-900
        py-4
          container
          mx-auto
          md:flex md:justify-between md:items-center font-semibold
        "
      >
        <div class="flex items-center justify-between ">
          <router-link to="/">
          <img class="w-16 h-16" src="../assets/logo.png" alt="">
        </router-link>
            
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="
                text-gray-50
                hover:text-gray-400
                focus:outline-none focus:text-gray-400
              "
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="
            flex-col
            mt-8
            space-y-4
            md:flex
            md:space-y-0
            md:flex-row
            md:items-center
            md:space-x-4
            md:mt-0
          "
        >
        <router-link
          to="/"
          class=" text-left text-lg block py-2 pl-3 pr-4 lg:inline-block lg:mt-0 text-gray-50 hover:text-slate-50 hover:ease-in duration-300 hover:rounded-lg hover:bg-gray-700">
         Home
        </router-link>
        <router-link
          to="/aboutus"
          class=" text-left text-lg block py-2 pl-3 pr-4 mt-4 lg:inline-block lg:mt-0 text-gray-50 hover:text-slate-50 hover:ease-in duration-300 hover:rounded-lg hover:bg-gray-700">
         About
        </router-link>
        <router-link
          to="/service"
          class=" text-left text-lg block py-2 pl-3 pr-4 mt-4 lg:inline-block lg:mt-0 text-gray-50 hover:text-slate-50 hover:ease-in duration-300 hover:rounded-lg hover:bg-gray-700">
         Services
        </router-link>
        <router-link
          to="/product"
          class=" text-left text-lg block py-2 pl-3 pr-4 mt-4 lg:inline-block lg:mt-0 text-gray-50 hover:text-slate-50 hover:ease-in duration-300 hover:rounded-lg hover:bg-gray-700">
        Products
        </router-link>
        <router-link
          to="/contact"
          class="text-left text-lg block py-2 pl-3 pr-4 mt-4 lg:inline-block lg:mt-0 text-gray-50 hover:text-slate-50 hover:ease-in duration-300 hover:rounded-lg hover:bg-gray-700">
          Contact
        </router-link>
        </ul>
        
      </nav>
    </div>
</template>
<script>
export default {
  name: "Navigation",
  data(){
    return {
      showMenu: false,
    }
  },
  methods: {
    toggleNav: function () {
        this.showMenu = !this.showMenu;
    },
  }
};

</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
</style>