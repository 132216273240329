import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import './assets/css/index.css';
import VueCarousel from "vue-carousel";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
Vue.use(VueCarousel);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
